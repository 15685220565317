// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-19-info-js": () => import("./../../../src/pages/covid19info.js" /* webpackChunkName: "component---src-pages-covid-19-info-js" */),
  "component---src-pages-form-submitted-js": () => import("./../../../src/pages/form-submitted.js" /* webpackChunkName: "component---src-pages-form-submitted-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-practice-js": () => import("./../../../src/pages/our-practice.js" /* webpackChunkName: "component---src-pages-our-practice-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

